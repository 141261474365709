@keyframes animateLoading {
  0% {transform: scale(1.2,1.2) rotate(0deg)}
  50% {transform: scale(1,1) rotate(360deg)}
  100% {transform: scale(1.2,1.2) rotate(360deg)}
}

.loading-animation {
  animation-name: animateLoading;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.image-loading {
  filter: blur(10px);
  clip-path: inset(0);
}
.image-loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}