body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'AGE';
  src: url('https://ayuhmalaysia.s3.ap-southeast-1.amazonaws.com/calonkeadilan/fonts/AktivGroteskEx.ttf') format('truetype');
}

@font-face {
  font-family: 'AGE Bold';
  src: url('https://ayuhmalaysia.s3.ap-southeast-1.amazonaws.com/calonkeadilan/fonts/AktivGroteskExBold.ttf') format('truetype');
}

@font-face {
  font-family: 'AGE XBold';
  src: url('https://ayuhmalaysia.s3.ap-southeast-1.amazonaws.com/calonkeadilan/fonts/AktivGroteskExXBold.ttf') format('truetype');
}

div {
  box-sizing: border-box;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.react-pdf__Document {
  font-family: AGE;
}